// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const SET_ROLE = 'SET_ROLE';

export const LABEL_GENERATOR = 'LABEL_GENERATOR';
// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// NOTES CONSTANTS
export const SELECTED_NOTES = 'SELECTED_NOTES';
export const SEARCH_NOTES = 'SEARCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';

// GROUP CONTANTS

export const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';

export const SET_SELECTED_ORDERS = 'SET_SELECTED_ORDERS';

export const SET_ACTIVE_GROUP_DATA_ERROR = 'SET_ACTIVE_GROUP_DATA_ERROR';

export const SET_ACTIVE_DELIVERY_GROUP = 'SET_ACTIVE_DELIVERY_GROUP';
export const SET_ACTIVE_DELIVERY_PARTNER_NO = 'SET_ACTIVVE_DELIVERY_PARTNER_NO';

export const SET_SELECTED_GROUPS = 'SET_SELECTED_GROUPS';
export const UNSET_SELECTED_GROUPS = 'UNSET_SELECTED_GROUPS';

export const SET_START_DATE_RANGE = 'SET_START_DATE_RANGE';
export const SET_END_DATE_RANGE = 'SET_END_DATE_RANGE';

export const SET_OR_UPDATE_TASK = 'SET_OR_UPDATE_TASK';

export const SET_ACTIVE_GROUP_DATA = 'SET_ACTIVE_GROUP_DATA';
export const SET_ACTIVE_GROUP_DATA_LOADING = 'SET_ACTIVE_GROUP_DATA_LOADING';

export const SET_ACTIVE_GROUP_DETAILS = "SET_ACTIVE_GROUP_DETAILS";
export const SET_ACTIVE_GROUP_DETAILS_ERROR = "SET_ACTIVE_GROUP_DETAILS_ERROR";
export const SET_ACTIVE_GROUP_DETAILS_LOADING = "SET_ACTIVE_GROUP_DETAILS_LOADING";

export const SET_ACTIVE_GROUP_DATA_ARRAY = "SET_ACTIVE_GROUP_DATA_ARRAY"
export const SET_ACTIVE_GROUP_ITEM_REQUIRED = "SET_ACTIVE_GROUP_ITEM_REQUIRED"
export const SET_ACTIVE_GROUP_BOOKING_QUOTES = "SET_ACTIVE_GROUP_BOOKING_QUOTES"
export const SET_ACTIVE_GROUP_BOOKING_COURIER = "SET_ACTIVE_GROUP_BOOKING_COURIER"
export const SET_ACTIVE_GROUP_ORDER_HISTORY_DATA = "SET_ACTIVE_GROUP_ORDER_HISTORY_DATA"
export const SET_ACTIVE_GROUP_ORDER_BOOKING_ERROR = "SET_ACTIVE_GROUP_ORDER_BOOKING_ERROR"
export const SET_ACITVE_GROUP_BOOKING_LOADING = "SET_ACITVE_GROUP_BOOKING_LOADING"

export const SET_ACTIVE_GROUP_ORDER_CONFIRMATION = "SET_ACTIVE_GROUP_ORDER_CONFIRMATION"
export const SET_ACTIVE_GROUP_ORDER_CONFIRMATION_STEP_COUNT = "SET_ACTIVE_GROUP_ORDER_CONFIRMATION_STEP_COUNT"
export const UNSET_ACTIVE_GROUP_ORDER_CONFIRMATION_STEP_COUNT = "UNSET_ACTIVE_GROUP_ORDER_CONFIRMATION_STEP_COUNT"

export const SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX = "SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX"
export const SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX_LOADING = "SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX_LOADING"

export const SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX_ERROR = "SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX_ERROR"

export const SET_ACTIVE_GROUP_LIST = "SET_ACTIVE_GROUP_LIST"
export const SET_ACTIVE_GROUP_LIST_LOADING = "SET_ACTIVE_GROUP_LIST_LOADING"
export const SET_ACTIVE_GROUP_LIST_ERROR = "SET_ACTIVE_GROUP_LIST_ERROR"
export const SET_ORDER_BY_DIVISION = "SET_ORDER_BY_DIVISION"
export const SET_FILTER_LIST_ORDER_GROUP = "SET_FILTER_LIST_ORDER_GROUP"
export const SET_CURRENT_FILTER_LIST_ORDER_GROUP = "SET_CURRENT_FILTER_LIST_ORDER_GROUP"

export const SET_ORDER_LIST = "SET_ORDER_LIST"
export const SET_ORDER_COUNT = "SET_ORDER_COUNT"
export const SET_ORDER_TOTAL_PAGE_NUMBER = "SET_ORDER_TOTAL_PAGE_NUMBER"

export const SET_GROUP_SUMMARY_LIST = "SET_GROUP_SUMMARY_LIST"
export const SET_GROUP_SUMMARY_LOADING = "SET_GROUP_SUMMARY_LOADING"
export const SET_GROUP_SUMMARY_ERROR = "SET_GROUP_SUMMARY_ERROR"

export const SET_LOADING_ORDER_LIST = "SET_LOADING_ORDER_LIST"
export const SET_ORDERPAGE_TOTAL_PAGE_NUMBER = "SET_ORDERPAGE_TOTAL_PAGE_NUMBER"


export const SET_CASH_COLLECTION = "SET_CASH_COLLECTION"
export const SET_CASH_COLLECTION_LOADING = "SET_CASH_COLLECTION_LOADING"
export const SET_CASH_COLLECTION_ERROR = "SET_CASH_COLLECTION_ERROR"
export const SET_CASH_COLLECTION_AMOUNT = "SET_CASH_COLLECTION_AMOUNT"
export const SET_GROUP_SEARCH_RESULTS = "SET_GROUP_SEARCH_RESULTS"

// CASH CONTANTS 
export const WALLERT_SUMMARY = "WALLERT_SUMMARY"
export const WALLERT_SUMMARY_ERROR = "WALLERT_SUMMARY_ERROR"
export const WALLERT_SUMMARY_LOADING = "WALLERT_SUMMARY_LOADING"

// INVENTORY CONTANTS
export const SET_ACTIVE_WAREHOUSE = 'SET_ACTIVE_WAREHOUSE';
export const SET_WAREHOUSE_LIST = 'SET_WAREHOUSE_LIST';
export const SET_STORAGE_LIST = 'SET_STORAGE_LIST';
export const SET_ITEM_LIST = 'SET_ITEM_LIST';
export const SET_ITEM_TYPE = 'SET_ITEM_TYPE';
export const SET_BATCH_LIST = 'SET_BATCH_LIST;';

// REPORT 
export const SET_REPORT_URL = 'SET_REPORT_URL';
export const SET_IS_REPORT_LOADING = 'SET_IS_REPORT_LOADING';
export const SET_REPORT_HISTORY_DATA = 'SET_REPORT_HISTORY_DATA';
export const SET_REPORT_HISTORY_DATA_PAGE_LENGTH = 'SET_REPORT_HISTORY_DATA_PAGE_LENGTH';



// ADVANCED

export const SET_PICKUP_LOCATION_DATA = 'SET_PICKUP_LOCATION_DATA';

export const SET_PICKUP_LOCATION_DATA_LOADING = 'SET_PICKUP_LOCATION_DATA_LOADING';

export const SET_COURIER_PARTNER_DATA = 'SET_COURIER_PARTNER_DATA';
export const SET_COURIER_PARTNER_DATA_LOADING = 'SET_COURIER_PARTNER_DATA_LOADING';

export const SET_PIN_CODE_DATA = 'SET_PIN_CODE_DATA';
export const SET_PIN_CODE_DATA_LOADING = 'SET_PIN_CODE_DATA_LOADING';

export const SET_PIN_CODE_SET_DATA = 'SET_PIN_CODE_SET_DATA';
export const SET_PIN_CODE_SET_DATA_LOADING = 'SET_PIN_CODE_SET_DATA_LOADING';

export const SET_OPTION_DATA = 'SET_OPTION_DATA';
export const SET_OPTION_DATA_LOADING = 'SET_OPTION_DATA_LOADING';
export const SET_OPTION_DATA_LENGTH = 'SET_OPTION_DATA_LENGTH';


export const OPS_FLOOR_DASHBOARD = 'OPS_FLOOR_DASHBOARD';
export const OPS_FLOOR_DASHBOARD_LOADING = 'OPS_FLOOR_DASHBOARD_LOADING';


export const SET_GROUP_DETAILS_BY_STATUS_SINGLE = 'SET_GROUP_DETAILS_BY_STATUS_SINGLE';
export const SET_GROUP_DETAILS_BY_STATUS_SINGLE_LOADING = 'SET_GROUP_DETAILS_BY_STATUS_SINGLE_LOADING';
export const SET_TOTAL_NUMBER_OF_UNGROUP = 'SET_TOTAL_NUMBER_OF_UNGROUP';


export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_ROLES = 'SET_USER_ROLES';

export const SET_UID = 'SET_UID';
export const SET_EMAIL = 'SET_EMAIL';

export const SET_GROUP_DASHBOARD_LOADING = 'SET_GROUP_DASHBOARD_LOADING';
export const SET_GROUP_COUNT_AND_ORDER_COUNT = 'SET_GROUP_COUNT_AND_ORDER_COUNT';

export const SET_GROUP_ORDER_DETAIL_CSV_LOADING = 'SET_GROUP_ORDER_DETAIL_CSV_LOADING';
export const SET_LABEL_GENERATE_PROGRESS_DATA = 'SET_LABEL_GENERATE_PROGRESS_DATA';
export const SET_LABEL_GENERATE_PROGRESS_LOADING = 'SET_LABEL_GENERATE_PROGRESS_LOADING';

export const SET_ACTIVE_GROUP_BOOKING_QUOTES_LOADING = 'SET_ACTIVE_GROUP_BOOKING_QUOTES_LOADING';
export const SET_BOOKING_SDD_QUOTES = 'SET_BOOKING_SDD_QUOTES';
export const SET_BOOKING_SDD_QUOTES_LOADING = 'SET_BOOKING_SDD_QUOTES_LOADING';

// Communication Constants

export const FETCH_ALL_TEMPLATES = 'FETCH_ALL_TEMPLATES';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const SET_LOADING_ORDER_QUICK_DELIVERY_LIST = 'SET_LOADING_ORDER_QUICK_DELIVERY_LIST';
export const SET_ORDERPAGE_QUICK_DELIVERTY_TOTAL_PAGE_NUMBER = 'SET_ORDERPAGE_QUICK_DELIVERTY_TOTAL_PAGE_NUMBER';

//
export const SET_ESCALATION = 'SET_ESCALATION';
export const SET_SHOW_ASSIGN_BOX = 'SET_SHOW_ASSIGN_BOX';

export const SET_LOADING_GROUP_STATUS_COUNT = 'SET_LOADING_GROUP_STATUS_COUNT';
export const SET_ALL_ACTION_LOADING = 'SET_ALL_ACTION_LOADING';


// FOR SELECTED GROUP MODAK AND PURANPOLI COUNT

export const SET_SELECTED_GROUP_MODAK_AND_PURANPOLI_COUNT = 'SET_SELECTED_GROUP_MODAK_AND_PURANPOLI_COUNT';
export const SET_ASSIGN_DELIVERY_STEP_LOADING = 'SET_ASSIGN_DELIVERY_STEP_LOADING';




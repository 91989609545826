import { SET_EMAIL, SET_ROLE, SET_TOKEN, SET_UID } from "../constants";


const INIT_STATE = {
	token: null,
	uid: null,
	userEmail: null,
	userRole:null
};

const authReducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SET_TOKEN:
			return {
				...state,
				token: action.data,
			};
		case SET_UID:
			return {
				...state,
				uid: action.data,
			};
		case SET_EMAIL:
			return {
				...state,
				userEmail: action.data
			}
		case SET_ROLE:
			return {
				...state,
				userRole: action.data
			}
		
		default:
			return state;
	}
};

export default authReducer;
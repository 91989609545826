import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import RootReducers from './Rootreducers';



const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, RootReducers)

// eslint-disable-next-line import/prefer-default-export

export const Store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);





export const PStore = persistStore(Store);




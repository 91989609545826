import { SET_IS_REPORT_LOADING, SET_REPORT_HISTORY_DATA, SET_REPORT_HISTORY_DATA_PAGE_LENGTH, SET_REPORT_URL } from "../constants";

const INIT_STATE = {
    setReportURL: '',
    setIsLoading: false,
    setReportHistoryData: [],
    setReportHistoryDataPageLength: 0,
};

const reportReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_REPORT_URL:
            return {
                ...state,
                setReportURL: action.url,
            };
        case SET_IS_REPORT_LOADING:
            return {
                ...state,
                setIsLoading: action.loading,
            };
        case SET_REPORT_HISTORY_DATA:
            return {
                ...state,
                setReportHistoryData: action.data,
            };
        case SET_REPORT_HISTORY_DATA_PAGE_LENGTH:
            return {
                ...state,
                setReportHistoryDataPageLength: action.count,
            };

        default:
            return state;
    }
};

export default reportReducer;
/* eslint no-underscore-dangle: 0 */
import { SET_OR_UPDATE_TASK, SET_ACTIVE_DELIVERY_GROUP, SET_ACTIVE_DELIVERY_PARTNER_NO, SET_ACTIVE_GROUP, SET_END_DATE_RANGE, SET_SELECTED_GROUPS, SET_SELECTED_ORDERS, SET_START_DATE_RANGE, UNSET_SELECTED_GROUPS, SET_ACTIVE_GROUP_DATA, SET_ACTIVE_GROUP_DATA_ERROR, SET_ACTIVE_GROUP_DATA_LOADING, SET_ACTIVE_GROUP_DETAILS, SET_ACTIVE_GROUP_DETAILS_ERROR, SET_ACTIVE_GROUP_DETAILS_LOADING, SET_ACTIVE_GROUP_ITEM_REQUIRED, SET_ACTIVE_GROUP_BOOKING_QUOTES, SET_ACTIVE_GROUP_BOOKING_COURIER, SET_ACTIVE_GROUP_ORDER_HISTORY_DATA, SET_ACTIVE_GROUP_ORDER_BOOKING_ERROR, SET_ACTIVE_GROUP_ORDER_CONFIRMATION, SET_ACTIVE_GROUP_ORDER_CONFIRMATION_STEP_COUNT, UNSET_ACTIVE_GROUP_ORDER_CONFIRMATION_STEP_COUNT, SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX, SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX_ERROR, SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX_LOADING, SET_ACTIVE_GROUP_LIST, SET_ACTIVE_GROUP_LIST_ERROR, SET_ACTIVE_GROUP_LIST_LOADING, SET_ORDER_BY_DIVISION, SET_FILTER_LIST_ORDER_GROUP, SET_CURRENT_FILTER_LIST_ORDER_GROUP, SET_ORDER_LIST, SET_ORDER_COUNT, SET_ORDER_TOTAL_PAGE_NUMBER, SET_GROUP_SUMMARY_LIST, SET_GROUP_SUMMARY_LOADING, SET_GROUP_SUMMARY_ERROR, SET_CASH_COLLECTION, SET_CASH_COLLECTION_LOADING, SET_CASH_COLLECTION_ERROR, SET_CASH_COLLECTION_AMOUNT, SET_GROUP_SEARCH_RESULTS, SET_GROUP_ORDER_DETAIL_CSV_LOADING, SET_LABEL_GENERATE_PROGRESS_LOADING, SET_LABEL_GENERATE_PROGRESS_DATA, SET_ACTIVE_GROUP_BOOKING_QUOTES_LOADING, SET_BOOKING_SDD_QUOTES, SET_BOOKING_SDD_QUOTES_LOADING, SET_ACITVE_GROUP_BOOKING_LOADING, SET_SHOW_ASSIGN_BOX, SET_ALL_ACTION_LOADING, SET_SELECTED_GROUP_MODAK_AND_PURANPOLI_COUNT, SET_ASSIGN_DELIVERY_STEP_LOADING } from '../constants';

const INIT_STATE = {
	activeGroup: 0,
	activeDeliveryGroup: 0,
	activeDeliveryPartnerNo: '',
	selectedOrders: [],
	activeGroupDatas: {},
	activeGroupDatasArray: [],
	activeGroupError: {},
	activeGroupLoading: true,
	activeGroupDetails: [],
	activeGroupDetailsError: {},
	activeGroupDetailsLoading: true,
	activeGroupItemRequired: [],
	setBookingQuotes: [],
	setIsCheckingCouurierRates: false,
	setBookGroupOrderHistoryData: [],
	setConfirmationResponse: {},
	setAlertMessageBooking: {},
	setShipmentDeliveryBoxApiData: [],
	setShipmentDeliveryBoxError: {},
	setShipmentDeliveryBoxLaoding: true,
	setAssignStepCount: 1,
	setActiveGroupList: [],
	setActiveGroupListLoading: true,
	setActiveGroupListError: {},
	setOrderByDivision: [],
	setOrderList: [],
	setOrderTotalPageNumber: 0,
	setOrderCounts: 0,
	setFilterListOrderGroup: [],
	setCurrentFilterListOrderGroupData: {},
	setGroupSummaryList: [],
	setGroupSummaryLoading: true,
	setGroupSummaryError: {},
	setCashCollectionOrder: [],
	setCashCollectionAmount: 0,
	setCashCollectionLoading: true,
	setGroupSearchResults: [],
	setCashCollectionError: {},
	selectedGroups: [],
	startDateRange: new Date(),
	endDateRange: new Date(),
	tasks: [],
	BookingSddQuotes: [],
	BookingSddQuotesLoading: false,
	setGroupOrderDetailCsvLoading: false,
	setLabelGenerateProgressData: [],
	setLabelGenerateProgressLoading: false,
	activeGroupBookingQuotesLoading: false,
	groupBookingLoading:false,
	showAssignBox:false,
	loadingForAllAction:false,
	countForSelectedGroupModalAndPuranpoli:[],
	assignDeliveryStepLoading:false,
};

const GroupReducer = (state = INIT_STATE, action) => {

	switch (action.type) {
		case SET_ACTIVE_GROUP:
			return {
				...state,
				activeGroup: action.groupId,
				selectedOrders: [],
				setAssignStepCount: 1
			};
		case SET_BOOKING_SDD_QUOTES:
			return {
				...state,
				BookingSddQuotes: action.data,
			};
		case SET_BOOKING_SDD_QUOTES_LOADING:
			return {
				...state,
				BookingSddQuotesLoading: action.loading,
			};
		case SET_ACTIVE_GROUP_DATA:
			return {
				...state,
				activeGroupDatas: action.groups,
			};
		case SET_ACTIVE_GROUP_BOOKING_QUOTES_LOADING:
			return {
				...state,
				activeGroupBookingQuotesLoading: action.loading,
			};
		case SET_GROUP_ORDER_DETAIL_CSV_LOADING:
			return {
				...state,
				setGroupOrderDetailCsvLoading: action.loading,
			};
		case SET_LABEL_GENERATE_PROGRESS_DATA:
			return {
				...state,
				setLabelGenerateProgressData: action.data,
			};
		case SET_LABEL_GENERATE_PROGRESS_LOADING:
			return {
				...state,
				setLabelGenerateProgressLoading: action.loading,
			};

		case SET_ACTIVE_GROUP_DATA_ERROR:
			return {
				...state,
				activeGroupError: action.error,
			};
		case SET_ACTIVE_GROUP_DATA_LOADING:
			return {
				...state,
				activeGroupLoading: action.loading,
			};
		case SET_ACTIVE_GROUP_DETAILS:
			return {
				...state,
				activeGroupDetails: action.details,
			};

		case SET_ACTIVE_GROUP_DETAILS_ERROR:
			return {
				...state,
				activeGroupDetailsError: action.error,
			};
		case SET_ACTIVE_GROUP_DETAILS_LOADING:
			return {
				...state,
				activeGroupDetailsLoading: action.loading,
			};
		case SET_ACTIVE_GROUP_ITEM_REQUIRED:
			return {
				...state,
				activeGroupItemRequired: action.item,
			};
		case SET_ACTIVE_GROUP_BOOKING_QUOTES:
			return {
				...state,
				setBookingQuotes: action.data,
			};
		case SET_ACTIVE_GROUP_BOOKING_COURIER:
			return {
				...state,
				setIsCheckingCouurierRates: action.rate,
			};
		case SET_ACTIVE_GROUP_ORDER_HISTORY_DATA:
			return {
				...state,
				setBookGroupOrderHistoryData: action.data,
			};
		case SET_ACTIVE_GROUP_ORDER_BOOKING_ERROR:
			return {
				...state,
				setAlertMessageBooking: action.error,
			};
		case SET_ACTIVE_GROUP_ORDER_CONFIRMATION:
			return {
				...state,
				setConfirmationResponse: action.data,
			};
		case SET_ACTIVE_GROUP_ORDER_CONFIRMATION_STEP_COUNT:
			return {
				...state,
				setAssignStepCount: action.count,
			};
		case UNSET_ACTIVE_GROUP_ORDER_CONFIRMATION_STEP_COUNT:
			return {
				...state,
				setAssignStepCount: action.count,
			};

		case SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX:
			return {
				...state,
				setShipmentDeliveryBoxApiData: action.data,
			};
		case SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX_LOADING:
			return {
				...state,
				setShipmentDeliveryBoxLaoding: action.loading,
			};
		case SET_ACTIVE_GROUP_SHIPMENT_DELIVERY_BOX_ERROR:
			return {
				...state,
				setShipmentDeliveryBoxError: action.error,
			};
		case SET_ACTIVE_GROUP_LIST:
			return {
				...state,
				setActiveGroupList: action.data,
			};
		case SET_ACTIVE_GROUP_LIST_LOADING:
			return {
				...state,
				setActiveGroupListLoading: action.loading,
			};

		case SET_ACTIVE_GROUP_LIST_ERROR:
			return {
				...state,
				setActiveGroupListError: action.error,
			};

		case SET_ACTIVE_DELIVERY_GROUP:
			return {
				...state,
				activeDeliveryGroup: action.groupId,
			};
		case SET_ORDER_BY_DIVISION:
			return {
				...state,
				setOrderByDivision: action.data,
			};
		case SET_FILTER_LIST_ORDER_GROUP:
			return {
				...state,
				setFilterListOrderGroup: action.data,
			};
		case SET_CURRENT_FILTER_LIST_ORDER_GROUP:
			return {
				...state,
				setCurrentFilterListOrderGroupData: action.data,
			};

		case SET_ORDER_LIST:
			return {
				...state,
				setOrderList: action.data,
			};

		case SET_ORDER_COUNT:
			return {
				...state,
				setOrderCounts: action.count,
			};

		case SET_ORDER_TOTAL_PAGE_NUMBER:
			return {
				...state,
				setOrderTotalPageNumber: action.count,
			};
		case SET_GROUP_SUMMARY_LIST:
			return {
				...state,
				setGroupSummaryList: action.data,
			};
		case SET_GROUP_SUMMARY_LOADING:
			return {
				...state,
				setGroupSummaryLoading: action.loading,
			};
		case SET_GROUP_SUMMARY_ERROR:
			return {
				...state,
				setGroupSummaryError: action.error,
			};
		// case SET_ORDERPAGE_TOTAL_PAGE_NUMBER:
		//   return {
		//     ...state,
		//     setOrderPageTotalPageNumber: action.count,
		//   };
		// case SET_LOADING_ORDER_LIST:
		//   return {
		//     ...state,
		//     setLoadedOrdersList: action.data,
		//   };
		case SET_CASH_COLLECTION:
			return {
				...state,
				setCashCollectionOrder: action.data,
			};

		case SET_CASH_COLLECTION_AMOUNT:
			return {
				...state,
				setCashCollectionAmount: action.count,
			};

		case SET_CASH_COLLECTION_LOADING:
			return {
				...state,
				setCashCollectionLoading: action.loading,
			};

		case SET_CASH_COLLECTION_ERROR:
			return {
				...state,
				setCashCollectionError: action.error,
			};
		case SET_GROUP_SEARCH_RESULTS:
			return {
				...state,
				setGroupSearchResults: action.data,
			};
		case SET_ACTIVE_DELIVERY_PARTNER_NO:
			return {
				...state,
				activeDeliveryPartnerNo: action.mobile,
			};

		case SET_SELECTED_ORDERS:
			return {
				...state,
				selectedOrders: action.selectedOrders,
			};

		case SET_SELECTED_GROUPS:
			console.log('selected groups #3');
			return {
				...state,
				selectedGroups: action.selectedGroups,
			};

		case UNSET_SELECTED_GROUPS:
			return {
				...state,
				selectedGroups: [],
			};

		case SET_START_DATE_RANGE:
			return {
				...state,
				startDateRange: action.date,
			};

		case SET_END_DATE_RANGE:
			return {
				...state,
				endDateRange: action.date,
			};
		case SET_ACITVE_GROUP_BOOKING_LOADING:
			return {
				...state,
				groupBookingLoading: action.loading,
			};
		case SET_SHOW_ASSIGN_BOX:
			return {
				...state,
				showAssignBox: action.data,
			};
		case SET_ALL_ACTION_LOADING:
			return {
				...state,
				loadingForAllAction: action.loading,
			};
		case SET_ASSIGN_DELIVERY_STEP_LOADING:
			return {
				...state,
				assignDeliveryStepLoading: action.loading,
			};
		case SET_SELECTED_GROUP_MODAK_AND_PURANPOLI_COUNT:
			return {
				...state,
				countForSelectedGroupModalAndPuranpoli: action.data,
			};	
		case SET_OR_UPDATE_TASK: {
			const tasks = JSON.parse(JSON.stringify(state.tasks))
			let foundTask = false;
			const { taskObj } = action;
			for (let ti = 0; ti < tasks.length; ti++) {
				const task = tasks[ti];
				if (task._id === taskObj._id) {
					tasks[ti] = taskObj;
					foundTask = true;
					break;
				}
			}

			if (!foundTask) {
				tasks.push(taskObj)
			}

			return {
				...state,
				tasks
			};
		}

		default:
			return state;
	}
};

export default GroupReducer;


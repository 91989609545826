import { OPS_FLOOR_DASHBOARD, OPS_FLOOR_DASHBOARD_LOADING, SET_GROUP_COUNT_AND_ORDER_COUNT, SET_GROUP_DASHBOARD_LOADING, SET_GROUP_DETAILS_BY_STATUS_SINGLE, SET_GROUP_DETAILS_BY_STATUS_SINGLE_LOADING, SET_LOADING_GROUP_STATUS_COUNT, SET_TOTAL_NUMBER_OF_UNGROUP, SET_USER_DATA, SET_USER_ROLES } from "../constants";


const INIT_STATE = {
    setGroupDetailsByStatus: {},
    setGroupDetailsByStatusFloorLoading: true,
    setGroupDetailsByStatusSingle: [],
    setGroupDetailsByStatusSingleLoading: true,
    setTotalNumberOfUngroup: [],
    setUserDataDashboard: [],
    setUserRoleData: [],
    groupDashboardLoading: true,
    groupCountAndOrderCount: {},
	 setLoadingGroupStatusCount:false,
};

const dashboardReducer = (state = INIT_STATE, action) => {


    switch (action.type) {
        case OPS_FLOOR_DASHBOARD:
            return {
                ...state,
                setGroupDetailsByStatus: action.data,
            };
        case OPS_FLOOR_DASHBOARD_LOADING:
            return {
                ...state,
                setGroupDetailsByStatusFloorLoading: action.data,
            };
        case SET_GROUP_DETAILS_BY_STATUS_SINGLE:
            return {
                ...state,
                setGroupDetailsByStatusSingle: action.data,
            };
        case SET_GROUP_DETAILS_BY_STATUS_SINGLE_LOADING:
            return {
                ...state,
                setGroupDetailsByStatusSingleLoading: action.data,
            };
        case SET_TOTAL_NUMBER_OF_UNGROUP:
            return {
                ...state,
                setTotalNumberOfUngroup: action.data,
            };
        case SET_USER_DATA:
            return {
                ...state,
                setUserDataDashboard: action.data,
            };
        case SET_USER_ROLES:
            return {
                ...state,
                setUserRoleData: action.data,
            };
        case SET_GROUP_DASHBOARD_LOADING:
            return {
                ...state,
                groupDashboardLoading: action.loading,
            };
        case SET_GROUP_COUNT_AND_ORDER_COUNT:
            return {
                ...state,
                groupCountAndOrderCount: action.data,
            };
			case SET_LOADING_GROUP_STATUS_COUNT:
				return {
					...state,
					setLoadingGroupStatusCount: action.loading,
				};
				

        default:
            return state;
    }
};

export default dashboardReducer;

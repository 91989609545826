import { SET_LOADING_ORDER_LIST, SET_LOADING_ORDER_QUICK_DELIVERY_LIST, SET_ORDERPAGE_QUICK_DELIVERTY_TOTAL_PAGE_NUMBER, SET_ORDERPAGE_TOTAL_PAGE_NUMBER } from "../constants";

const INIT_STATE = {
    setLoadedOrdersList: [],
    setOrderPageTotalPageNumber: 0,
	setLoadedOrdersListQuickDelivery: [],
	setOrderPageQuickDeliveryTotalPageNumber: 0,
};

const cashReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ORDERPAGE_TOTAL_PAGE_NUMBER:
            return {
                ...state,
                setOrderPageTotalPageNumber: action.count,
            };
        case SET_LOADING_ORDER_LIST:
            return {
                ...state,
                setLoadedOrdersList: action.data,
            };
			case SET_LOADING_ORDER_QUICK_DELIVERY_LIST:
				return {
					...state,
					setLoadedOrdersListQuickDelivery: action.data,
				};
			case SET_ORDERPAGE_QUICK_DELIVERTY_TOTAL_PAGE_NUMBER:
				return {
					...state,
					setOrderPageQuickDeliveryTotalPageNumber: action.count,
				};
        default:
            return state;
    }
};

export default cashReducer;
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';


/* ***Layouts**** */
const FullLayout = lazy(() => import('../layouts/full-layout/FullLayout'));
const BlankLayout = lazy(() => import('../layouts/blank-layout/BlankLayout'));
/* ***End Layouts**** */
const Lead = lazy(() => import('../views/advanced/lead/lead'));
const SingleLead = lazy(() => import('../views/advanced/singleLead/singleLead'));
const Error = lazy(() => import('../views/authentication/Error'));
const Login = lazy(() => import('../views/authentication/Login'));
const Register = lazy(() => import('../views/authentication/Register'));
const ResetPassword = lazy(() => import('../views/authentication/ResetPassword'));

const GroupsSummary = lazy(() => import('../views/groups/summary'));
const GroupLists = lazy(() => import('../views/groups/list'));

const WalletSummary = lazy(() => import('../views/cash/wallet'));

const CollectionsSummary = lazy(() => import('../views/cash/collections'));

const ReportsSummary = lazy(() => import('../views/reports'));


const Feedback = lazy(() => import('../views/feedback/Feedback'));


const Options = lazy(() => import('../views/options/Options'));
const SyncLog = lazy(() => import('../views/syncLog/SyncLog'));
const Communication = lazy(() => import('../views/advanced/communication/Communication'));
const CommunicationTemplates = lazy(() => import('../views/communication/Communications'));

const Order = lazy(() => import('../views/orders/SingleOrder'));
const OrderDashboard = lazy(() => import('../views/order-status/Dashboard'));

// const OrderGroupView = lazy(() => import('../views/orders/OrderGroupView'));

// const WarehouseTable = lazy(() => import('../views/tables/WarehouseTable'));
// const ItemTable = lazy(() => import('../views/tables/ItemTable'));
// const ItemTypeTable = lazy(() => import('../views/tables/ItemTypeTable'));

// -----invontory-management-----
const Item = lazy(() => import('../views/inventory-management/items/Items'));
const ItemType = lazy(() => import('../views/inventory-management/items/ItemType'));
const ItemTransaction = lazy(() => import('../views/inventory-management/items/ItemTransactions'));

const StorageUnit = lazy(() => import('../views/inventory-management/warehouse/storage'));
const SinglePageeUnit = lazy(() =>
	import('../views/inventory-management/warehouse/singleUnit/SingleUnitPage'),
);

const Warehouse = lazy(() => import('../views/inventory-management/warehouse/warehouse'));
const SinglewWrehouse = lazy(() =>
	import('../views/inventory-management/warehouse/singlewarehouse/SingleWarehousePage'),
);

const OrderGroupView = lazy(() => import('../views/orders/OrderGroupView'))
const OrdersListView = lazy(() => import('../views/orders/OrdersListView'))

const PickUpLoc = lazy(() => import('../views/advanced/pickUpLocation/pickUpLoc'));

const CourierPartner = lazy(() => import('../views/advanced/courierPartner/CourierPartner'))
// const Options = lazy(() => import('../views/advanced/options/Options'))
const IncidentReporting = lazy(() => import('../views/advanced/incidentReporting/IncidentReporting'))
// const SyncLog = lazy(() => import('../views/advanced/syncLog/SyncLog'))
const PinCode = lazy(() => import('../views/advanced/pincode/PinCode'))
const SddCharges = lazy(() => import('../views/advanced/samedaydeliverycharges/SddCharges'))

const SinglePickUpLoc = lazy(() => import('../views/advanced/pickUpLocation/SinglePickUpLoc'))
const PinCodeSet = lazy(() => import('../views/advanced/pincodeSet/PincodeSet'))
const SinglePinCodeSet = lazy(() => import('../views/advanced/pincodeSet/SinglePinCodeSet'))
const OrdersByDivision = lazy(() => import('../views/OrdersByDivision/OrdersByDivision'))

const FirstOrderData = lazy(() => import('../views/cs-managment/FirstOrderCxData'))
const CartAbandoned = lazy(() => import('../views/cs-managment/CartAbandoned'))


const OpsFloorDashboard = lazy(() => import('../views/dashboards/ops-floor-dashboard/opsFloorDashboard'))
const GroupDashboard = lazy(() => import('../views/dashboards/group-dashboard/groupDashboard'))
const UsersDashboard = lazy(() => import('../views/dashboards/user-dashboard/UserDashboard'))
const UsersRoleDashboard = lazy(() => import('../views/dashboards/user-dashboard/UserRoleDashboard'))


const Invoice = lazy(() => import('../views/inventory-management/invoice/Invoice'));
const InvoiceItems = lazy(() => import('../views/inventory-management/invoice/InvoiceItem'));

const Batch = lazy(() => import('../views/inventory-management/batch/batch'));

const SingleBatchPage = lazy(() => import('../views/inventory-management/batch/singlebatch/SingleBatchPage'));
const BactchMaterial = lazy(() => import('../views/inventory-management/batch/batchMaterial'));
const SerialDiscard = lazy(() => import('../views/inventory-management/batch/discardserial/SerialDiscard'));

const SerialNumberPage = lazy(() =>
	import('../views/inventory-management/SerialNumber/SerialNumber'),
);

const Product = lazy(() => import('../views/mobile/product/Product'));
const Category = lazy(() => import('../views/mobile/category/Category'));
const Coupon = lazy(() => import('../views/mobile/coupon/Coupon'));
const Banner = lazy(() => import('../views/mobile/banners/Banners'));
const SingleProduct = lazy(() => import('../views/mobile/product/SingleProduct'));
const ProductVariant = lazy(() => import('../views/mobile/productVariant/ProductVariant'));
const ChatBotDetails = lazy(() => import('../views/bot/chatbot/ChatBotDetails'));


const CreateGroupLogic = lazy(() => import('../views/advanced/createGroupLogic/CreateGroupLogicPage'));

// ------marketing routes ----///

const MarketingCampaignsView = lazy(() => import('../views/marketing-management/marketing-campaign/MarketingCampaingView'));
const ScheduleCampaing = lazy(() => import('../views/marketing-management/schedule-campaign/ScheduleCampaing'));



const Communicationslog = lazy(() => import('../views/marketing-management/marketing-campaign/SingleCommunicationLog'));

const SearchResult = lazy(() => import('../views/searchresult/SearchResult'));
const SingleCustomer = lazy(() => import('../views/customer/SingleCustomer'));
const CustomerDashboard = lazy(() => import('../views/customer/CustomerDashboard'));
const OrderDashboard2 = lazy(() => import('../views/dashboards/orders/OrderDashboard'));
const OrderListQuick = lazy(() => import('../views/quickDelivery/OrderListViewQuick'));
const RiderMessageSendDashboard = lazy(() => import('../views/dashboards/ridermessage-dashboard/RiderMessage'));
const CustomerSupport = lazy(() => import('../views/escalations/customersupport/CustomerSupport'));
const Operations = lazy(() => import('../views/escalations/operation/Operations'));
const ActiveLogs = lazy(() => import('../views/activelog/ActiveLog'));
const ShipmentActivity = lazy(() => import('../views/shipmentmanagent/ShipmentFilterPage'));
const PiekarStatuPage = lazy(() => import('../views/shipmentmanagent/PikartOrderStatus'));
const NotifyMe = lazy(() => import('../views/mobile/notifyme/notifyMeAll'));



/* ****Routes***** */

const tostError = (error) => {
	toast.error(error, {
		duration: 2000,
	});
};

let cureentUser = false;

const RequireAuth = ({ children }) => {

	const currentFont = useSelector((state) => state.authReducer.token);

	if (currentFont) {
		cureentUser = true;
	}
	else {
		tostError("Token expired")
	}
	return cureentUser ? children : <Navigate to="/auth/login" />
}

// 
const Router = [
	{
		path: '/',
		element: <FullLayout />,
		children: [
			{ path: '/', element: <Navigate to="/groups/list/processing" /> },
			{ path: '/valhalla', element: <Navigate to="/groups/list/processing" /> },
			{
				path: '/groups/summary/', exact: true, element: <RequireAuth > <GroupsSummary /> </RequireAuth>
			},
			{
				path: '/groups/list/:status/:groupId', exact: true, element: <RequireAuth > <GroupLists /> </RequireAuth>
			},
			{
				path: '/groups/list/:status', exact: true, element: <RequireAuth ><GroupLists /></RequireAuth>
			},

			{
				path: '/delivery/partners', exact: true, element: <RequireAuth ><GroupLists /></RequireAuth>
			},
			{
				path: '/cash/wallet', exact: true, element: <RequireAuth ><WalletSummary /></RequireAuth>
			},
			{
				path: '/cash/collections', exact: true, element: <RequireAuth ><CollectionsSummary /></RequireAuth>
			},

			{
				path: '/inventory/items/item', exact: true, element: <RequireAuth > <Item /> </RequireAuth>
			},
			{ path: '/inventory/items/itemtypes', exact: true, element: <RequireAuth > <ItemType /></RequireAuth> },
			{
				path: '/inventory/items/itemtransaction', exact: true, element: <RequireAuth ><ItemTransaction /></RequireAuth>
			},

			{
				path: '/inventory/warehouses/storage', exact: true, element: <RequireAuth ><StorageUnit /></RequireAuth>
			},
			{
				path: '/inventory/warehouses/storage/:id', exact: true, element: <RequireAuth ><SinglePageeUnit /></RequireAuth>
			},

			{
				path: '/inventory/warehouses/warehouse/:id', exact: true, element: <RequireAuth > <SinglewWrehouse /> </RequireAuth>
			},
			{
				path: '/inventory/warehouses/warehouse', exact: true, element: <RequireAuth ><Warehouse /></RequireAuth>
			},

			{
				path: '/inventory/invoices/invoice', exact: true, element: <RequireAuth> <Invoice /></RequireAuth>
			},
			{
				path: '/inventory/warehouses/invoiceitem', exact: true, element: <RequireAuth ><InvoiceItems /></RequireAuth>
			},

			{
				path: '/inventory/batchs/batch', exact: true, element: <RequireAuth> <Batch /></RequireAuth>
			},
			{ path: '/inventory/batchs/batch/:id', exact: true, element: <RequireAuth> <SingleBatchPage /></RequireAuth> },
			{ path: '/inventory/batchs/bacthmaterial', exact: true, element: <RequireAuth><BactchMaterial /></RequireAuth> },
			{ path: '/inventory/batchs/discardserialnumber', exact: true, element: <RequireAuth><SerialDiscard /></RequireAuth> },

			{ path: '/inventory/serial/serialnumber', exact: true, element: <RequireAuth> <SerialNumberPage /></RequireAuth> },

			{ path: '/communications', exact: true, element: <Communication /> },

			{ path: '/reports', exact: true, element: <ReportsSummary /> },

			{
				path: '/feedback', exact: true, element: <RequireAuth>  <Feedback /> </RequireAuth>
			},
			{
				path: '/lead', exact: true, element: <RequireAuth> <Lead /> </RequireAuth>
			},
			{ path: '/singleLead/:id', exact: true, element: <RequireAuth><SingleLead /></RequireAuth> },
			{ path: '/options', exact: true, element: <RequireAuth> <Options /></RequireAuth> },
			{ path: '/syncLog', exact: true, element: <RequireAuth><SyncLog /> </RequireAuth> },
			{ path: '/communication', exact: true, element: <RequireAuth> <Communication /> </RequireAuth> },
			{ path: '/communicationTemplate', exact: true, element: <RequireAuth> <CommunicationTemplates /> </RequireAuth> },

			{
				path: '/orders/order/:orderId', element: <RequireAuth> <Order /> </RequireAuth>
			},
			{ path: '/orders/list/:status', element: <RequireAuth> <OrdersListView /> </RequireAuth> },
			{ path: '/orders/list/quickdelivery/:status', element: <RequireAuth> <OrderListQuick /> </RequireAuth> },
			{ path: '/groups/create', element: <RequireAuth>  <OrderGroupView /></RequireAuth> },
			{ path: '/advanced/pickuplocation', exact: true, element: <RequireAuth>  <PickUpLoc /></RequireAuth> },
			{ path: '/advanced/pickuplocation/:id', exact: true, element: <RequireAuth>  <SinglePickUpLoc /></RequireAuth> },

			{
				path: '/advanced/courierpartner', exact: true, element: <RequireAuth> <CourierPartner /> </RequireAuth>
			},

      // cs management 

			{ path: '/cs-dashboard/first-order-data', exact: true, element: <FirstOrderData /> },

			{ path: '/cs-dashboard/cart-abandoned-data', exact: true, element: <CartAbandoned /> },

			
			// { path: '/advanced/options', exact: true, element: <Options /> },
			// { path: '/advanced/syncLog', exact: true, element: <SyncLog /> },
			{ path: '/advanced/incidentReporting', exact: true, element: <IncidentReporting /> },

			{
				path: '/advanced/pincode', exact: true, element: <RequireAuth>  <PinCode /> </RequireAuth>
			},
			{
				path: '/advanced/sddchargessheet', exact: true, element: <RequireAuth>  <SddCharges /> </RequireAuth>
			},
		
			{ path: '/advanced/pincodeset', exact: true, element: <RequireAuth> <PinCodeSet /></RequireAuth> },
			{ path: '/advanced/pincodeset/:id', exact: true, element: <RequireAuth>  <SinglePinCodeSet /></RequireAuth> },
			{ path: '/advanced/createGroupLogic', exact: true, element: <RequireAuth>  <CreateGroupLogic /></RequireAuth> },

			{ path: '/groups/orderbydivision', exact: true, element: <RequireAuth>  <OrdersByDivision /></RequireAuth> },

			{ path: '/mobile/product', exact: true, element: <Product /> },
			{ path: '/mobile/category', exact: true, element: <Category /> },
			{ path: '/mobile/coupon', exact: true, element: <Coupon /> },
			{ path: '/mobile/banner', exact: true, element: <Banner /> },
			{ path: '/mobile/product/:id', exact: true, element: <SingleProduct /> },
			{ path: '/mobile/productvariant', exact: true, element: <ProductVariant /> },
			{ path: '/mobile/notifyme', exact: true, element: <NotifyMe /> },
	


		
			{ path: '/chatbot/details', exact: true, element: <ChatBotDetails /> },

			{ path: '/marketing-management/marketing-campaign-view', exact: true, element: <MarketingCampaignsView /> },
			{ path: '/marketing-management/schedule-marketing-campaign', exact: true, element: <ScheduleCampaing /> },

			{ path: '/marketing-management/communication-log/:id', exact: true, element: <Communicationslog /> },
			{
				path: '/dashboard/ops-floor-dashboard', exact: true, element: <RequireAuth ><OpsFloorDashboard /> </RequireAuth>
			},
			{
				path: '/dashboard/groups-dashboard', exact: true, element: <RequireAuth ><GroupDashboard /></RequireAuth>
			},
			{
				path: '/dashboard/groups-dashboard/:id', exact: true, element: <RequireAuth > <GroupDashboard /></RequireAuth>
			},
			{
				path: '/dashboard/user-dashboard', exact: true, element: <RequireAuth ><UsersDashboard /></RequireAuth>
			},
			{
				path: '/dashboard/order-dashboard', exact: true, element: <RequireAuth ><OrderDashboard2 /> </RequireAuth>
			},
			{
				path: '/dashboard/user-role-dashboard', exact: true, element: <RequireAuth > <UsersRoleDashboard /></RequireAuth>
			},
			{
				path: '/dashboard/rider-message-dashboard', exact: true, element: <RequireAuth > <RiderMessageSendDashboard /></RequireAuth>
			},
			{
				path: '/order-status', exact: true, element: <RequireAuth > <OrderDashboard /></RequireAuth>
			},

			{
				path: 'customer/:search', element: <RequireAuth> <SearchResult /></RequireAuth>
			},
			{
				path: '/dashboard/customer', exact: true, element: <RequireAuth > <CustomerDashboard /></RequireAuth>
			},
			{
				path: '/dashboard/customer/:id', exact: true, element: <RequireAuth > <SingleCustomer /></RequireAuth>
			},
			{
				path: '/escalation/customersupport', exact: true, element: <RequireAuth > <CustomerSupport /></RequireAuth>
			},
			{
				path: '/escalation/operations', exact: true, element: <RequireAuth > <Operations /></RequireAuth>
			},
			{
				path: '/activelogs/useractivity', exact: true, element: <RequireAuth > <ActiveLogs /></RequireAuth>
			},

			{
				path: '/shipment/shipmentActivity', exact: true, element: <RequireAuth > <ShipmentActivity /></RequireAuth>
			},
			{
				path: '/shipment/piekartOrderStatus', exact: true, element: <RequireAuth > <PiekarStatuPage /></RequireAuth>
			},
			// { path: '/tables/Warehouse-table', element: <WarehouseTable /> },
			//   { path: '/tables/Item-table', element: <ItemTable /> },
			//   { path: '/tables/ItemType-table', element: <ItemTypeTable /> },

			{ path: '*', element: <Navigate to="/auth/404" /> },
		],
	},
	{
		path: '/auth',
		element: <BlankLayout />,
		children: [
			{ path: '404', element: <Error /> },
			{ path: '/login', element: <Login /> },
			{ path: '/register', element: <Register /> },
			{ path: '/reset-password', element: <ResetPassword /> },
			{ path: '*', element: <Navigate to="/auth/404" /> },
		],
	},


];

export default Router;

RequireAuth.propTypes = {
	children: PropTypes.node.isRequired
};

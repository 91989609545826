
import { SET_ESCALATION } from "../constants";


const INIT_STATE =  {
   escalationData:{}
}

const escalationReducer = (state = INIT_STATE , action) => {

	switch(action.type){
		case SET_ESCALATION:
			return {
				...state,
				escalationData: action.data,
			};

		default:
			return state;
	}

}

export default escalationReducer;

import { SET_COURIER_PARTNER_DATA, SET_COURIER_PARTNER_DATA_LOADING, SET_OPTION_DATA, SET_OPTION_DATA_LENGTH, SET_OPTION_DATA_LOADING, SET_PICKUP_LOCATION_DATA, SET_PICKUP_LOCATION_DATA_LOADING, SET_PIN_CODE_DATA, SET_PIN_CODE_DATA_LOADING, SET_PIN_CODE_SET_DATA, SET_PIN_CODE_SET_DATA_LOADING } from "../constants";

const INIT_STATE = {

    setPickUpLocationData: [],
    setPickUpLocationLoading: true,
    setCourierPartnerData: [],
    setCourierPartnerLoading: true,
    setPinCodeData: [],
    setPinCodeLoading: true,
    setPinCodeSetApiCall: [],
    setPinCodeSetLoading: true,
    setOptionData: [],
    setOptionDataLength: [],
    setOptionLoading: true,
};

const advancedReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PICKUP_LOCATION_DATA:
            return {
                ...state,
                setPickUpLocationData: action.data,
            };
        case SET_PICKUP_LOCATION_DATA_LOADING:
            return {
                ...state,
                setPickUpLocationLoading: action.loading,
            };

        case SET_COURIER_PARTNER_DATA:
            return {
                ...state,
                setCourierPartnerData: action.data,
            };
        case SET_COURIER_PARTNER_DATA_LOADING:
            return {
                ...state,
                setCourierPartnerLoading: action.loading,
            };
        case SET_PIN_CODE_DATA:
            return {
                ...state,
                setPinCodeData: action.data,
            };
        case SET_PIN_CODE_DATA_LOADING:
            return {
                ...state,
                setPinCodeLoading: action.loading,
            };
        case SET_PIN_CODE_SET_DATA:
            return {
                ...state,
                setPinCodeSetApiCall: action.data,
            };
        case SET_PIN_CODE_SET_DATA_LOADING:
            return {
                ...state,
                setPinCodeSetLoading: action.loading,
            };
        case SET_OPTION_DATA:
            return {
                ...state,
                setOptionData: action.data,
            };
        case SET_OPTION_DATA_LOADING:
            return {
                ...state,
                setOptionLoading: action.loading,
            };
        case SET_OPTION_DATA_LENGTH:
            return {
                ...state,
                setOptionDataLength: action.count,
            };
        default:
            return state;
    }
};

export default advancedReducer;
import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import chatReducer from './chats/ChatReducer';
import notesReducer from './notes/NotesReducer';
import emailReducer from './email/EmailReducer';
import groupReducer from './groups/GroupReducer';
import InventoryReducer from './inventory/InventoryReducers';
import cashReducer from './cash/CashReducer';
import orderReducer from './order/OrderReducer';
import reportReducer from './report/ReportReducer';
import advancedReducer from './advanced/AdvancedReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import authReducer from './auth/authReducer';
import TemplateReducer from './communication/TemplateReducer';
import escalationReducer from './escalation/EscalationReducer';


const RootReducers = combineReducers({
  InventoryReducer,
  CustomizerReducer,
  chatReducer,
  notesReducer,
  emailReducer,
  groupReducer,
  cashReducer,
  reportReducer,
  orderReducer,
  advancedReducer,
  dashboardReducer,
  authReducer,
  TemplateReducer,
	escalationReducer
});

export default RootReducers;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { PersistGate } from 'redux-persist/integration/react'
import App from './App';
import { PStore, Store } from './redux/Store';
import './data';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Spinner from './views/spinner/Spinner';

// comment to trigger redeploy

// axios.defaults.baseURL = 'http://flexy-aws.coyogi.com';

axios.defaults.baseURL = 'https://flexy-aws.coyogi.com';

// axios.defaults.baseURL = 'http://localhost:6060/';


const client = new ApolloClient({
  uri: '/api/graphql',
  cache: new InMemoryCache(),
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={Store}>
      <PersistGate loading={null} persistor={PStore}>
        <Suspense fallback={<Spinner />}>
          <BrowserRouter>
            <Toaster />
            <App />
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>

  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

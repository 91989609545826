/* eslint no-underscore-dangle: 0 */
import {
  SET_ACTIVE_WAREHOUSE,
  SET_WAREHOUSE_LIST,
  SET_ITEM_LIST,
  SET_STORAGE_LIST,
  SET_ITEM_TYPE,
  SET_BATCH_LIST,
} from '../constants';

const INIT_STATE = {
  activeWarehouseId: null,
  warehouseList: [],
  storageList: [],
  itemList: [],
  itemType: [],
  batchList: [],
};

const InventoryReducer = (state = INIT_STATE, action) => {
  console.log(action);

  switch (action.type) {
    case SET_ACTIVE_WAREHOUSE:
      return {
        ...state,
        activeWarehouseId: action.warehouseId,
      };

    case SET_WAREHOUSE_LIST:
      return {
        ...state,
        warehouseList: action.warehouseList,
      };
    case SET_STORAGE_LIST:
      return {
        ...state,
        storageList: action.storageList,
      };
    case SET_ITEM_LIST:
      return {
        ...state,
        itemList: action.itemList,
      };
    case SET_ITEM_TYPE:
      return {
        ...state,
        itemType: action.itemType,
      };
    case SET_BATCH_LIST:
      return {
        ...state,
        batchList: action.batchList,
      };

    default:
      return state;
  }
};

export default InventoryReducer;

import React, {useEffect,useState} from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import socketIO from 'socket.io-client';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';

const socket = socketIO.connect('https://flexy-aws.coyogi.com');

const App = () => {

  const toastOrder=(orderId)=>{
    toast.error(`Book delivery for this order #${orderId}`,{
      duration: 3000,
      position:'top-right',  
      icon: '🕒',
      style: {
        padding: '16px',
        width: '100%',
        // backgroundColor: '#121212',
        fontWeight: 'bold',
        // color: 'white',
        fontSize: '20px',
      }
    })
}
 
  const [customOrderNotif,setCustomOrderNotif]=useState([]);

  useEffect(()=>{
    socket.on('customOrder',({message})=>{
      console.log(message);
      setCustomOrderNotif(message);
      showNotif();
    });
    return () => {
      socket.off("customOrder");
    };
  },[customOrderNotif]);

  const showNotif=()=>{
    if(customOrderNotif){
      for(let i=0;i<customOrderNotif.length;i++){
        toastOrder(customOrderNotif[i]);
      }
    }
  }

// const toastOrder = (orderId) => {
//     toast.custom(<span style={{
//             padding: '16px',
//             backgroundColor: '#121212',
//             fontWeight: 'bold',
//             color: 'white',
//             fontSize: '20px',
//             display: 'inline'
//         }} > Book delivery for order # <a href={`/orders/order/${orderId}`} target='_blank' rel="noreferrer" style={{textDecoration: 'none'}} >{orderId}</a></span>, {
//         duration: 2000,
//         position:'top-right',  
//         icon: '🕒'      
//     });
// };


socket.on('customerOrder',({message})=>{
  toastOrder(message);
})


  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {routing}
      </RTL>
    </ThemeProvider>
  );
};

export default App;

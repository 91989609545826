import { FETCH_ALL_TEMPLATES, CREATE_TEMPLATE, UPDATE_TEMPLATE, DELETE_TEMPLATE } from "../constants";
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

const INIT_STATE = {
  templates: []
};

const TemplateReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_TEMPLATES: {
      return {
        ...state,
        templates: action.payload
      };
    }
    case CREATE_TEMPLATE: {
      return {
        ...state,
        templates: [...state.templates, action.payload]
      };
    }
    case UPDATE_TEMPLATE: {
      return {
        ...state,
        templates: state.templates.map((template) =>
          template._id === action.payload._id ? action.payload : template
        )
      };
    }
    case DELETE_TEMPLATE: {
      return {
        ...state,
        templates: state.templates.filter(
          (template) => template._id !== action.payload
        )
      };
    }
    default:
      return state;
  }
};

export default TemplateReducer;

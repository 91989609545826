import { WALLERT_SUMMARY, WALLERT_SUMMARY_ERROR, WALLERT_SUMMARY_LOADING } from "../constants";


const INIT_STATE = {
    walletSummary: {},
    walletSummaryLoading: true,
    walletSummaryError: {},
};

const cashReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case WALLERT_SUMMARY:
            return {
                ...state,
                walletSummary: action.data,
            };
        case WALLERT_SUMMARY_ERROR:
            return {
                ...state,
                walletSummaryError: action.error,
            };
        case WALLERT_SUMMARY_LOADING:
            return {
                ...state,
                walletSummaryLoading: action.loading,
            };
        default:
            return state;
    }
};

export default cashReducer;
